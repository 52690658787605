<template>
  <div class="subourservice">
    <div class="sub-banner bg" />
    <div class="wrapper">
      <div class="bread">
        <div class="icon"><i class="el-icon-caret-right" /></div>
        <div class="text">您的位置：首页  <i>></i>  <span>网络货运平台</span></div>
      </div>
      <div class="sub-menu">
        <a href="/baseHome/Platform" title="网络货运平台" class="on">网络货运平台</a>
        <a href="/baseHome/AppDownLoad" title="APP介绍及下载">APP介绍及下载</a>
      </div>
    </div>

    <div class="wrapper">
      <div class="sub-title" style="letter-spacing:3px;">系统核心功能</div>
      <div class="circles">
        <img src="../../assets/images/yuan4.png" style="margin-left:95px; margin-top:-11px;">
        <div class="circles1"><img src="../../assets/images/yuan1.png"></div>
        <div class="circles2"><img src="../../assets/images/yuan2.png"></div>
      </div>
    </div>

    <div class="gongneng">
      <div class="sub-title" style="letter-spacing:3px;">平台功能</div>
      <div class="scales"><img src="../../assets/images/gn.png"></div>
    </div>

    <div class="sub-title" style="letter-spacing:3px; padding-bottom: 30px;">运营模式</div>
    <div class="ourtext wrapper">按照山水物联“1+5”的经营思路，以“网络货运板块”为主体，延伸出“网上商城及维保业务板块”、“软件开发板块”、“新能源板块”、“能源板块”、“保险服务板块”，通过网络货运板块资源的整合、市场的拓展、平台车辆注册会员和销售收入的增加，丰富其它5个板块的后期增值服务，增加平台黏性，实现货运平台与生产企业、上下游产业链的产、供、销业务运输环节的全流程的融合，实现整个建材行业甚至跨行业的规模发展，实现政策性盈利以外的利润增长。
    </div>
    <div class="wrapper scales" style="margin:50px auto 80px; text-align: center;"><img src="../../assets/images/ms.png"></div>
  </div>

</template>

<script>

export default {
  name: '',
  components: {
  },
  data() {
    return {

    }
  },

  mounted() {

  },
  methods: {

  }
}
</script>

<style lang="scss">
@import "./../../assets/scss/sub.scss";
.subourservice {
  .bg{background:url(./../../assets/images/cpbg.jpg) }
  .circles{position: relative; margin-top: 20px;
    .circles1{position: absolute; top: 50%; left: 50%; margin-top: -163px; margin-left: -163px;animation:circleRoate 40s infinite linear;}
    .circles2{position: absolute; top: 50%; left: 50%; margin-top: -225px; margin-left: -225px;animation:circleRoate1 40s infinite linear;}
  }
  .gongneng{ background: #f5f5f5; margin:70px 0 0; text-align: center; padding-bottom: 70px;}

  //
  //
  //
  .ourtext{ line-height: 39px; color: #222; font-size: 16px;}
  .ourcircle{ position:relative; text-align: center;
    .wave2{position: absolute; left: 50%; top:0;width: 35px; height: 35px;
      .w0{background: #2e7ee1; width: 11px; height: 11px; border-radius: 50%; left: 12px; top: 12px; position: absolute;}
      .w1,.w2,.w3,.w4{background: #2e7ee1;}
      .text1{ position: absolute; right: 50px; top: 5px; font-size: 24px; color: #0062db; width: 500px; text-align: right;}
    }
    .title1{ position: absolute; left: 0; right: 0;  font-size: 24px; color: #0062db;}
  }

}
</style>
